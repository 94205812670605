export default function SvgToolIcon({color, width, borderWidth, height}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height ? height : 48.5} viewBox="0 0 51.384 48.528">
            <g id="Groupe_32" data-name="Groupe 32" transform="translate(2.93 2.1)">
                <g id="Groupe_5" data-name="Groupe 5" transform="translate(0 0)">
                    <g id="Groupe_1" data-name="Groupe 1" transform="translate(2.762 7.272)">
                        <line id="Ligne_1" data-name="Ligne 1" y1="6.545" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_2" data-name="Ligne 2" x1="5.091" y2="3.636" transform="translate(0 0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_3" data-name="Ligne 3" x1="6.545" y1="4.363" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    </g>
                    <g id="Groupe_2" data-name="Groupe 2" transform="matrix(0.602, -0.799, 0.799, 0.602, 0, 30.094)">
                        <line id="Ligne_1-2" data-name="Ligne 1" y1="6.545" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_2-2" data-name="Ligne 2" x1="5.091" y2="3.636" transform="translate(0 0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_3-2" data-name="Ligne 3" x1="6.545" y1="4.363" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    </g>
                    <g id="Groupe_3" data-name="Groupe 3" transform="matrix(-0.999, -0.035, 0.035, -0.999, 42.581, 36.221)">
                        <line id="Ligne_1-3" data-name="Ligne 1" y1="6.545" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_2-3" data-name="Ligne 2" x1="5.091" y2="3.636" transform="translate(0 0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_3-3" data-name="Ligne 3" x1="6.545" y1="4.363" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    </g>
                    <g id="Groupe_4" data-name="Groupe 4" transform="translate(45.523 14.267) rotate(127)">
                        <line id="Ligne_1-4" data-name="Ligne 1" y1="6.545" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_2-4" data-name="Ligne 2" x1="5.091" y2="3.636" transform="translate(0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                        <line id="Ligne_3-4" data-name="Ligne 3" x1="6.545" y1="4.363" transform="translate(0 3.636)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    </g>
                    <path id="Tracé_9" data-name="Tracé 9" d="M19.5,11.25l5.99,4.068" transform="translate(-2.557 7.84)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <path id="Tracé_10" data-name="Tracé 10" d="M10927.068,2340.691l6.216-3.98" transform="translate(-10904.257 -2317.649)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_4" data-name="Ligne 4" y1="6.545" transform="translate(23.125 23.271)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_5" data-name="Ligne 5" y2="8.14" transform="translate(23.125 36.221)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_6" data-name="Ligne 6" x1="5.818" y2="3.636" transform="translate(23.125 40.725)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_7" data-name="Ligne 7" x2="5.818" y2="3.636" transform="translate(17.307 40.725)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_8" data-name="Ligne 8" y2="8.727" transform="translate(23.125 0.727)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_9" data-name="Ligne 9" y1="4.363" x2="6.545" transform="translate(16.58 0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                    <line id="Ligne_10" data-name="Ligne 10" x1="5.818" y1="4.363" transform="translate(23.125 0)" fill="none" stroke={color} stroke-linecap="round" stroke-width={borderWidth}/>
                </g>
            </g>
        </svg>
    );
}
