import React from "react";
import bootomArrow from '../../assets/bootomArrow.svg'
import whiteArrow from '../../assets/whiteArrow.svg'
import underline from '../../assets/underline.svg'
import {useTranslation} from "react-i18next";
export default function LandingPart({width}) {

    const { t } = useTranslation();

    return(
        <div className={'landingPart'} style={{zIndex: 10}}>

            <div style={{display: 'flex'}}>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h2 className={'call'}>{t('landingPart.slogan')}</h2>
                    <h1 className={'mainTitle'} dangerouslySetInnerHTML={{ __html: t('landingPart.mainTitle') }}></h1>
                    <img src={underline} alt="" className={'underline'}/>
                    {width > 774 && <p className={'classicSubtitle'}>{t('landingPart.subtitle')}</p>}

                </div>
            </div>

            <div className={'discoverInfo'}>
                {width > 555 && <img src={bootomArrow} alt=""/>}
                <p style={{margin: 0}} dangerouslySetInnerHTML={{ __html: t('landingPart.discover') }}></p>
            </div>

            <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 20, marginBottom: 0,}}>

                <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                    <div className={'callToAction bounceHover'}>
                        {t('landingPart.requestProposal')}
                        <img src={whiteArrow} alt=""/>
                    </div>
                </a>

                {width > 550 &&
                    <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                        <div className={'lightCallToAction bounceHover'}>
                            {t('header.bookAppointment')}
                        </div>
                    </a>
                }

            </div>
        </div>
    )
}
