import React, { useRef, useEffect } from "react";
import testimony1 from '../../assets/testimony1.jpeg'
import testimony2 from '../../assets/testimony2.jpg'
import testimony3 from '../../assets/testimony3.jpg.webp'
import testimony4 from '../../assets/testimony4.jpg.webp'
import testimony5 from '../../assets/testimony5.webp'
import testimony6 from '../../assets/testimony6.jpg'
import testimony7 from '../../assets/testimony7.jpg'
import testimony8 from '../../assets/testimony8.jpeg'
import testimony9 from '../../assets/testimony9.jpg'
import testimony10 from '../../assets/testimony10.jpg'
import whiteArrow from "../../assets/whiteArrow.svg";
import leftRondArrow from "../../assets/leftRondArrow.svg";
import {useTranslation} from "react-i18next";
import trustPilot from "../../assets/trustPilot.png";
const Carousel = ({width}) => {
    const ref = useRef(null);

    const { t } = useTranslation();

    const squaresData = [
        {
            id: 1,
            pp: testimony2,
            name: "Julien Lefebvre",
            profession: t('reviews.professions.restaurantOwner'),
            testimony: t('reviews.testimony.julienLefebvreTestimony')
        },
        {
            id: 2,
            pp: testimony1,
            name: "Sandrine Moreau",
            profession: t('reviews.professions.craftsperson'),
            testimony: t('reviews.testimony.sandrineMoreauTestimony')
        },
        {
            id: 3,
            pp: testimony4,
            name: "Mathieu Petit",
            profession: t('reviews.professions.consultant'),
            testimony: t('reviews.testimony.mathieuPetitTestimony')
        },
        {
            id: 4,
            name: "Émilie Bernard",
            pp: testimony5,
            profession: t('reviews.professions.merchant'),
            testimony: t('reviews.testimony.emilieBernardTestimony')
        },
        {
            id: 5,
            name: "Sébastien Garcia",
            pp: testimony6,
            profession: t('reviews.professions.realEstateAgent'),
            testimony: t('reviews.testimony.sebastienGarciaTestimony')
        },
        {
            id: 6,
            name: "Caroline Leroux",
            pp: testimony3,
            profession: t('reviews.professions.architect'),
            testimony: t('reviews.testimony.carolineLerouxTestimony')
        },
        {
            id: 7,
            name: "Nicolas Girard",
            pp: testimony7,
            profession: t('reviews.professions.fitnessCoach'),
            testimony: t('reviews.testimony.nicolasGirardTestimony')
        },
        {
            id: 8,
            name: "Stéphanie Dupont",
            pp: testimony10,
            profession: t('reviews.professions.photographer'),
            testimony: t('reviews.testimony.stephanieDupontTestimony')
        },
        {
            id: 9,
            name: "Thomas Dubois",
            pp: testimony8,
            profession: t('reviews.professions.eventPlanner'),
            testimony: t('reviews.testimony.thomasDuboisTestimony')
        },
        {
            id: 10,
            name: "Aurélie Martin",
            pp: testimony9,
            profession: t('reviews.professions.trainer'),
            testimony: t('reviews.testimony.aurelieMartinTestimony')
        }
    ];



    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current) {
                // Check if you're close to the end of the carousel
                if (ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth - 350) {
                    // Reset the scrolling position
                    ref.current.scrollTo({ left: 0, behavior: "smooth" });
                } else {
                    ref.current.scrollBy({ left: 330, behavior: "smooth" });
                }
            }
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    const scrollLeft = () => {
        if (ref.current) {
            ref.current.scrollBy({ left: -330, behavior: 'smooth' }); // 330 est la largeur approximative d'un élément, ajustez en fonction de votre design.
        }
    }

    const scrollRight = () => {
        if (ref.current) {
            ref.current.scrollBy({ left: 330, behavior: 'smooth' });
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFF'}}>
            <p className={'satisfied'}>{t('reviews.satisfied')}</p>
            <a href={'https://fr.trustpilot.com/review/72h-agency.com'} target="_blank">
                <img src={trustPilot} alt="" className={'trustPilot'}/>
            </a>

            <div ref={ref} className="carousel">
            {squaresData.map((square, index) => (
                        <div>
                            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                                <div key={square.id} className="square">
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                                        <img src={square.pp} alt="" className={'profilePicture'}/>
                                        <div>
                                            <p className={'name'}>{square.name}</p>
                                            <p className={'profession'}>{t(square.profession)}</p>
                                        </div>
                                    </div>
                                    <p className={'testimony'}
                                       dangerouslySetInnerHTML={{__html: t(square.testimony)}}></p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <div style={{display: 'flex', gap: 20, marginBottom: 45}}>
                    <img src={leftRondArrow} alt="" className={'leftRondArrow'} onClick={scrollLeft}/>
                    <img src={leftRondArrow} alt="" className={'leftRondArrow inverse'} onClick={scrollRight}/>
                </div>

                <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                    <div className={'callToAction bounceHover'} style={{marginBottom: 150}}>
                        {width > 550 ? t('reviews.callToActionLarge') : t('reviews.callToActionSmall')}
                        <img src={whiteArrow} alt=""/>
                    </div>
                </a>
        </div>
);

};

export default Carousel;
