import React, {useState} from "react";
import plus from "../../assets/plusIcon.svg"
import moins from "../../assets/moins.svg";
import {useTranslation} from "react-i18next";
import i18next from 'i18next';

export default function FrequentlyAskedQuestions({width}) {

    const currentLanguage = i18next.language;

    const { t } = useTranslation();

    const questionsFr = [
        {
            "quest": "Quand vous dites 72h, à quoi faites vous référence ?",
            "button": "Je suis tenté !",
            "answer": "Nous rêvons tous de voir nos projets se réaliser en un claquement de doigt, de passer au suivant, d’entretenir une dynamique innovante et déterminée. \n\nPourtant, quand il s’agit de créer ou refondre son site web… La plaie. En effet, le délai moyen pour un site web réalisé par une agence est de 2 mois ! \n\nVous avez le temps d’attendre ? Surement pas. \n\nEn nous faisant confiance pour tout le processus et en validant chaque étape, nous garantissons que votre site web sera prêt et opérationnel en 72h après la validation de la maquette.\n\nTentant non ?"
        },
        {
            "quest": "Demander une maquette, est-ce engageant ?",
            "button": "Démarrez sans vous engager !",
            "answer": "NON, \n\nSans maquette, comment nous faire confiance ? \n\nPour vous convaincre, nous réalisons une maquette de site web adaptée à votre entreprise et 100% originale. \n\nDéterminés à vous séduire, nous nous impatientons à l’idée de vous la présenter ! \n\nSi la maquette vous plait, votre site web est réalisé en 72h. \n\nDans le cas contraire, nous ne sommes surement pas les bons interlocuteurs, vous serez fixé !\n\nDonnez nous quelques informations sur votre projet, nous nous occupons du reste :"
        },
        {
            "quest": "Puis-je effectuer des modifications sur la maquette proposée ?",
            "button": "Demander une maquette",
            "answer": "OUI, \n\nEt pas qu’une fois. \n\nÀ deux reprises lors  du processus de création et programmation, nous êtes invité à apporter votre contribution si vous ne sentez nécessaire. \n\nAu moment de la présentation de la maquette et à la publication de la version test. \n\nNous sommes déjà impatient d’avoir vos retours sur notre travail !"
        },
        {
            "quest": "Quel est l’avantage de 72h par rapport à d’autres agences ?",
            "button": "Démarrer maintenant avec 72h",
            "answer": "Le temps, le prix et la réponse aux précédentes questions. \n\nLaissez-nous vous le démontrer !"
        },
        {
            "quest": "Les sites sont-ils vraiment programmés par des développeurs ?",
            "button": "none",
            "answer": "OUI, \n\nDe nombreux outils existent désormais pour réaliser des sites web sans une ligne de code, mais ils connaissent des limites. \n\nNous n’aimons pas les limites. En code, tout est réalisable !"
        },
        {
            "quest": "Comment passer à l’action ?",
            "button": "Choisir un créneau de RDV",
            "answer": "Tout simplement.\n\nPrenez rendez-vous avec notre équipe, informez-nous de votre projet de site web. \n\nLaissez faire les professionnels !\n\nC’est par ici 👇"
        }
    ]

    const questionsEn = [
        {
            "quest": "When you say 72h, what are you referring to?",
            "button": "I'm tempted!",
            "answer": "We all dream of seeing our projects come to life in a snap, moving on to the next one, and maintaining a dynamic, innovative, and determined momentum. Yet, when it comes to creating or overhauling your website... It's a pain. In fact, the average time for a website made by an agency is 2 months! \n\nDo you have time to wait? Probably not. \n\nBy entrusting us with the whole process and validating each step, we guarantee that your website will be ready and operational in 72h after the mock-up validation.\n\nTempting, isn't it?"
        },
        {
            "quest": "Is asking for a mock-up binding?",
            "button": "Start without committing!",
            "answer": "NO, \n\nWithout a mock-up, how can you trust us? \n\nTo convince you, we create a 100% original website mock-up tailored to your company. \n\nEager to win you over, we can't wait to show it to you! \n\nIf you like the mock-up, your website is done in 72h. \n\nOtherwise, we might not be the right fit, and you'll know!\n\nGive us some details about your project, and we'll handle the rest:"
        },
        {
            "quest": "Can I make modifications to the proposed mock-up?",
            "button": "Ask for a mock-up for free",
            "answer": "YES, \n\nAnd not just once. \n\nTwice during the creation and programming process, you're invited to give your input if you feel it's necessary. \n\nDuring the mock-up presentation and when publishing the test version. \n\nWe're already eager to hear your feedback on our work!"
        },
        {
            "quest": "What's the advantage of 72h compared to other agencies?",
            "button": "Start now with 72h",
            "answer": "The time, the price, and the answers to the previous questions. \n\nLet us show you!"
        },
        {
            "quest": "Are websites really programmed by developers?",
            "button": "none",
            "answer": "YES, \n\nMany tools now exist to make websites without writing a line of code, but they have their limitations. \n\nWe don't like limitations. In coding, anything is possible!"
        },
        {
            "quest": "How to take action?",
            "button": "Choose a meeting slot",
            "answer": "It's simple.\n\nSchedule a meeting with our team, let us know about your website project. \n\nLet the professionals handle it!\n\nRight this way 👇"
        }
    ];



    function Question({question, answer, button}) {

        const [showAnswer, setShowAnswer] = useState(false)

        return(
            <div
                style={{maxHeight: showAnswer ? 1000 : width < 550 ? 75 : 100}}
                className={'question'}>
                <div
                    className={'headerQuestion'}
                    onClick={() => {
                        if (showAnswer) {
                            setShowAnswer(false)
                        } else {
                            setShowAnswer(true)
                        }

                    }}
                >
                    <p className={'questionTitle'}>{question}</p>
                    <img src={showAnswer ? moins : plus} alt="" className={'plus'}/>
                </div>

                <p className={'answer'}>{answer}</p>

                {button !== 'none' &&
                    <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                        <div style={{display: 'flex'}}>
                            <div className={'button'}>
                                {button}
                            </div>
                        </div>
                    </a>

                }

            </div>
        )
    }

    return(
        <div className={'frequentlyAskedQuestions'}>
            <p className={'classicTitle'} style={{width: 550, marginTop: 100}} dangerouslySetInnerHTML={{__html: t('faq.title')}}></p>
            <p className={'subtitle'}>{t('faq.subtitle')}</p>

            {currentLanguage.includes('fr') ?

                questionsFr.map((question, index) => (
                    <Question question={question.quest} answer={question.answer} button={question.button}/>
                ))

                :

                questionsEn.map((question, index) => (
                    <Question question={question.quest} answer={question.answer} button={question.button}/>
                ))

            }

        </div>
    )
}
