import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/Home";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import TermsServices from "./components/Legal/TermsServices";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={HomePage}/>
                <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
                <Route path="/terms-services" exact component={TermsServices}/>
            </Switch>
        </Router>
    );
}

export default App;
