import React, {useEffect, useState, useRef} from "react";
import opus from '../../assets/opus.png'
import opusAi from '../../assets/opusAi.png'
import adventis from '../../assets/adventis.png'
import kbi from '../../assets/kbi.png'
import perrotRichard from '../../assets/perrotRichard.png'
import whiteArrow from "../../assets/whiteArrow.svg";
import img2 from "../../assets/img2.png";
import {useTranslation} from "react-i18next";

export default function LastWork({width}) {

    const { t } = useTranslation();

    function StickComponent() {
        const [isSticky, setIsSticky] = useState(true);
        const rightRef = useRef(null);

        const handleScroll = () => {
            if (rightRef.current) {
                const { top } = rightRef.current.getBoundingClientRect();
                if (top <= 0) {
                    setIsSticky(false);
                } else {
                    setIsSticky(true);
                }
            }
        };

        useEffect(() => {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        return (
            <div className="containerStick">
                <div className={`left ${isSticky ? 'sticky' : ''}`}>
                    <div style={{ paddingLeft: width < 550 ? 0 : 30, paddingBottom: 0}}>
                        <p className={'classicTitle'} style={{textAlign: width < 550 ? 'center' : 'start', marginTop: 0, width: 530}} dangerouslySetInnerHTML={{ __html: t('discoverComponent.classicTitle') }} />
                        <div className={'bar'}/>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 530, flexDirection: width < 657 ? 'column' : 'row', maxWidth: '90vw'}}>
                            <p className={'chiffre'} dangerouslySetInnerHTML={{ __html: t('discoverComponent.sitesCreated') }} />
                            <p className={'chiffre'} dangerouslySetInnerHTML={{ __html: t('discoverComponent.dedicatedProfessionals') }} />
                        </div>
                        <div style={{display: 'flex', marginTop: 50, justifyContent: 'center', width: 530, maxWidth: '90vw'}}>
                            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                                <div className={'callToAction bounceHover'}>
                                    {t('discoverComponent.callToAction')}
                                    <img src={whiteArrow} alt=""/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className="lastWorkStick">
            <StickComponent/>
            <div className={width > 1200 ? 'workContainer' : 'lilWorkContainer'}>
                <Work img={kbi} imgBack={img2} link={''} width={width} />
                <Work img={adventis} imgBack={img2} link={''} width={width} />
                <Work img={perrotRichard} imgBack={img2} width={width} />
                <Work img={opus} imgBack={img2} link={''} width={width} />
                <Work img={opusAi} imgBack={img2} link={''} width={width} />
            </div>
        </div>
    )
}

function Work({img, link, imgBack, width}) {

    return(
        <div className={'work'}>
            {width > 1200 ?
                <>
                    <div className={'workFace front'}>
                        <img src={img} alt=""/>
                    </div>

                    <div className={'workFace back'}>
                        <img src={imgBack} alt=""/>
                    </div>
                </>
                :
                <img src={img} alt=""/>

            }



        </div>

    )
}
