import React from "react";
import './_expertise.scss'
import {getExpertiseList} from "./ExpertiseList";
import {useTranslation} from "react-i18next";
import whiteArrow from "../../assets/whiteArrow.svg";
export default function Expertise() {

    const { t } = useTranslation();
    const expertiseList = getExpertiseList(t);

    function ItemPoint({icon, title, subtitle}) {

        return(
            <div className={'itemPoint'}>
                <img src={icon} alt="" className={'icon'}/>
                <p className={'titlePoint'}>{title}</p>
                <p  className={'subtitlePoint'}>{subtitle}</p>
            </div>
        )
    }

    return(
        <div className={'expertise'} id={'expertises'}>
            <div className={'textPart'}>
                <h2 className={'titleExpertise'}>Découvrez l'ensemble de nos <span
                    style={{color: '#5E72EC', fontStyle: 'italic'}}>expertises.</span></h2>
                <p className={'subtitleExpertise'}>

                    Si votre but est de <span style={{color: '#5E72EC', fontWeight: '700'}}>concevoir un site web révolutionnaire, de démarrer un commerce électronique,</span>
                    d'optimiser votre stratégie de marketing numérique ou de perfectionner votre référencement, notre
                    équipe est là pour vous accompagner. Nous mettons à votre disposition des stratégies durables et
                    performantes qui s'alignent sur vos exigences.
                </p>

                <p className={'subtitleExpertise secondSubtitle'}>
                    En outre, nous prenons en main la refonte de vos projets, assurons la maintenance et l'hébergement
                    de vos sites web, et développons des tactiques de SEO avancées afin de consolider votre visibilité
                    sur internet.
                </p>
                <div style={{display: 'flex', justifyContent: "center"}}>
                    <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                        <div className={'callToAction bounceHover'} style={{marginTop: 30}}>
                            {t('discoverComponent.callToAction')}
                            <img src={whiteArrow} alt=""/>
                        </div>
                    </a>
                </div>

            </div>
            <div className={'pointParts'}>
                {expertiseList.map((expertise, index) => {
                    return <ItemPoint icon={expertise.icon} title={expertise.title} subtitle={expertise.subtitle}/>;
                })}
            </div>
        </div>
    )
}
