import React from "react";
import websiteDesigns from '../../assets/websiteDesigns.png'
export default function WebsiteDesigns() {

    return(
        <div className={'websiteDesigns'}>
            <img src={websiteDesigns} alt=""/>
        </div>
    )
}
