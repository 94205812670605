import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(LanguageDetector)  // Ajoutez ceci pour utiliser le détecteur de langue du navigateur
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            // Ordre des moyens de détection
            order: ['htmlTag', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],

            // Clés de recherche pour le paramètre de requête
            lookupQuerystring: 'lng',

            // Clés de recherche pour le cookie
            lookupCookie: 'i18next',

            // Clés de recherche pour le localStorage
            lookupLocalStorage: 'i18nextLng',

            // Clés de recherche pour le sessionStorage
            lookupSessionStorage: 'i18nextLng',

            // Nombre de jours avant que les cookies expirent
            cookieMinutes: 10,

            // URL de votre serveur API si vous utilisez un endpoint pour déterminer la langue
            // lookupFromPathIndex: 0,

            // Cache les langues définies
            caches: ['localStorage', 'cookie'],

            // Langue à utiliser si aucune n'est trouvée
            fallbackLng: 'en'
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
