export default function SvgLeftIcon({color, width}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height="27.261" viewBox="0 0 28.37 27.261">
            <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(2.5 3.52)">
                <path id="Tracé_18" data-name="Tracé 18" d="M7.5,18H30.87" transform="translate(-7.5 -7.89)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path id="Tracé_19" data-name="Tracé 19" d="M18,7.5l8.416,10.11L18,27.719" transform="translate(-3.045 -7.5)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
            </g>
        </svg>
    );
}
