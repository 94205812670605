import MainMenu from "../components/Main Menu/MainMenu";
import React, {useEffect, useState} from "react";
import LandingPart from "../components/Landing Part/LandingPart";
import WebsiteDesigns from "../components/Website Designs/WebsiteDesigns";
import Timeline from "../components/Timeline/Timeline";
import LastWork from "../components/Last Work/LastWork";
import Pricing from "../components/Pricing/Pricing";
import Carousel from "../components/Carousel/Carousel";
import Footer from "../components/Footer/Footer";
import FrequentlyAskedQuestions from "../components/Frequently Asked Questions/FrequentlyAskedQuestions";
import CallToAction from "../components/Call to action/CallToAction";
import Expertise from "../components/Expertise/Expertise";

export default function Home() {

    const [width, setWidth] = useState(800);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
        return () => window.removeEventListener("resize", Update);
    }, []);

    function Update() {
        setWidth(window.innerWidth);
    }

    return (
        <div className={'page'}>
            <MainMenu width={width}/>
            <LandingPart width={width}/>

            <WebsiteDesigns/>

            <Timeline/>
            <div id={'lastWork'}>
                <LastWork width={width}/>
            </div>
            <Expertise/>
            <div>
                <Pricing width={width}/>
            </div>
            <Carousel width={width}/>
            <FrequentlyAskedQuestions width={width}/>
            <CallToAction/>
            <Footer width={width}/>
        </div>
    );
}
