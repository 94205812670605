import icon from "../../assets/72h_logo.svg"
import codeIcon from "../../assets/codeIcon.svg"
import ecommerceIcon from "../../assets/ecommerceIcon.svg"
import cloudIcon from "../../assets/cloudIcon.svg"
import settingsicon from "../../assets/settingsicon.svg"
import reonfteicon from "../../assets/reonfteicon.svg"
import seoIcon from "../../assets/seoIcon.svg"
export const getExpertiseList = (t) =>
    [
        {
            icon: ecommerceIcon,
            title: "Création de site e-commerce",
            subtitle: "Notre agence web est spécialisée dans la création de site e-commerce avec Prestashop et Shopify."
        },
        {
            icon: reonfteicon,
            title: "Refonte de site internet",
            subtitle: "Donnez un nouveau souffle à votre site internet pour de meilleures performances et une experience utilisateur optimale."
        },
        {
            icon: cloudIcon,
            title: "Hébergement web",
            subtitle: "Solutions d'hébergement fiables et sécurisées pour assurer la disponibilité constante de votre site internet."
        },
        {
            icon: settingsicon,
            title: "Maintenance de site internet",
            subtitle: "Assistance professionnelle 24h/24 pour surveiller, mettre oà jour et maintenir votre site internet."
        },
        {
            icon: seoIcon,
            title: "Référencement naturel (SEO)",
            subtitle: "Optimisation de la visibilité de votre site internet grace à une stratégie de référencement naturel efficace."
        },
        {
            icon: codeIcon,
            title: "Création de site internet",
            subtitle: "Notre agence de création de site web est spécialisée dans le développement de sites corporates sous Wordpress."
        },

    ];
