import React from 'react';
import SvgLeftIcon from "../../assets/SvgLeftIcon";
import callToAction from './_callToAction.scss';
import logo from "../../assets/72h_logo.svg";
import { useTranslation } from 'react-i18next'; // importez le hook useTranslation

export default function CallToAction() {
    const { t } = useTranslation(); // utilisez le hook pour obtenir la fonction de traduction t

    return(
        <div className={'callToActionComponent'}>
            <div className={'containerCTA'}>
                <div className={'chooseItToday'}>
                    <img src={logo} alt="" className={'logo'}/>
                    {t('callToAction.startToday')}
                </div>

                <p className={'titleCTA'}>{t('callToAction.title')}<span style={{color: '#b8ffb0', fontStyle: 'italic'}}>{t('callToAction.official')}</span></p>

                <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                    <div className={'tryItFree'}>
                        {t('callToAction.scheduleMeeting')}
                        <SvgLeftIcon color={'#FFF'} width={15}/>
                    </div>
                </a>
            </div>
        </div>
    );
}
