import React from "react";
import logo from '../../assets/72h_logo.svg'
import { useTranslation } from 'react-i18next';
import {NavLink} from "react-router-dom";

export default function Footer({width}) {

    const { t } = useTranslation();

    const scrollToElement = (selector) => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return(
        <div className={'footer'}>
            <div className={'containerFooter'}>
                <div style={{marginLeft: 30}}>
                    <img src={logo} alt="" className={'logo'}/>
                    <p className={'littleTitleGrey'} style={{marginBottom: 5}}>{t('footer.followUs')}</p>
                    <a href="https://www.linkedin.com/company/72h-agency/">
                        <p className={'LinkFooter'}>Linkedin</p>
                    </a>
                    <a href="https://www.instagram.com/72h.agency?igsh=eHpna3k4Y2VpYjd4">
                        <p className={'LinkFooter'}>Instagram</p>
                    </a>
                    <p className={'littleTitleGrey'}
                       style={{marginTop: 50, fontSize: 13}}>{t('footer.copyright')}</p>
                </div>

                <div style={{display: 'flex', marginRight: 30, flexDirection: width < 600 ? 'column' : 'row'}}>

                    {width > 700 &&
                        <div>
                            <p className={'littleTitleGrey'}>{t('footer.start')}</p>
                            <p className={'LinkFooter'}  onClick={() => scrollToElement("#pricing")}>{t('footer.pricing')}</p>
                            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                                <p className={'LinkFooter'}>{t('footer.scheduleMeeting')}</p>
                            </a>
                        </div>
                    }

                    <div style={{marginLeft: width < 600 ? 0 : 70}}>
                        <p className={'littleTitleGrey'}>{t('footer.legal')}</p>
                        <NavLink to="/terms-services">
                            <p className={'LinkFooter'}>{t('footer.termsConditions')}</p>
                        </NavLink>
                        <NavLink to="/privacy-policy">
                            <p className={'LinkFooter'}>{t('footer.privacyPolicy')}</p>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}
