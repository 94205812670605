import React from 'react';
import MainMenu from "../Main Menu/MainMenu";
import Footer from "../Footer/Footer";


export default function PrivacyPolicy() {

    return(

        <div className={'page'}>

            <MainMenu/>

            <div style={{display: "flex", justifyContent: 'center', width: '100vw'}}>
                <div className={'legalDocumentContainer'}>

                    <h1>Politique de Confidentialité 72h</h1>
                    <p>OPUS France, Inc. (« OPUS France », « nous » ou « notre ») propose des services informatiques de conception, réalisation et maintenance de site web sous l’appellation « 72h ». Cette Politique de confidentialité vise à vous aider à comprendre comment nous collectons, utilisons, traitons et partageons vos informations personnelles, et à vous informer et vous permettre d'exercer vos droits en matière de confidentialité.</p>

                    <h2>1. PORTÉE ET MISES À JOUR DE CETTE Politique DE CONFIDENTIALITÉ</h2>
                    <p>Cette Politique de confidentialité s'applique aux informations personnelles traitées par nous, y compris les informations collectées via nos sites web, services et autres offres en ligne ou hors ligne (collectivement désignés par le terme "Services"). Sauf indication contraire, cette Politique de confidentialité ne s'applique pas aux informations personnelles fournies par nos clients lors de l'utilisation de nos Services (« Données des Clients »), qui sont régies par les politiques de confidentialité respectives des clients concernant la collecte et l'utilisation des Données des Clients. Notre traitement des Données des Clients est régi par les contrats que nous avons conclus avec nos clients, et non par cette Politique de confidentialité. Toute question ou demande concernant les Données des Clients doit être adressée à l'équipe juridique ou de confidentialité du client concerné.</p>

                    <p><strong>Modifications de notre Déclaration de Confidentialité.</strong> Nous nous réservons le droit de modifier cette Politique de confidentialité à tout moment. Nous vous informerons de tout changement significatif de cette Politique de confidentialité dans la mesure requise par la loi applicable. Vous comprenez et acceptez que si vous continuez à utiliser nos Services après l'entrée en vigueur de la nouvelle Politique de confidentialité, vous serez considéré comme ayant accepté la Politique de confidentialité mise à jour.</p>

                    <h2>2. INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS</h2>
                    <p>Les types d'informations personnelles que nous collectons dépendent de la manière dont vous interagissez avec nous et nos Services, ainsi que des exigences de la loi applicable. Comme décrit ci-dessous, nous collectons des informations que vous nous fournissez, des informations que nous collectons automatiquement lorsque vous utilisez nos Services, et des informations provenant d'autres sources telles que des services tiers et des organisations.</p>

                    <h3>A. Informations Personnelles que Vous Nous Fournissez Directement</h3>

                    <ul>
                        <li>
                            <strong>Calendly :</strong>  Lorsque vous programmez un rendez-vous commercial avec notre équipe, nous pouvons collecter des informations personnelles telles que votre nom, adresse e-mail, numéro de téléphone et détails professionnels.
                        </li>
                        <li>
                            <strong>Achats :</strong> Nous pouvons collecter des informations personnelles et des détails d'achat, y compris des informations de paiement, de votre part. Les paiements effectués via nos Services sont traités par des processeurs de paiement tiers. Nous ne collectons ni ne stockons directement aucune information de carte de paiement saisie via nos Services, mais nous pouvons recevoir des informations liées au paiement.
                        </li>
                        <li>
                            <strong>Vos communications avec nous :</strong> Lorsque vous demandez des informations sur nos Services, vous inscrivez à notre newsletter, programmes éducatifs, ou d'autres offres, demandez du support client ou technique, ou communiquez autrement avec nous, nous pouvons collecter des informations personnelles telles que votre adresse e-mail, numéro de téléphone ou adresse postale.
                        </li>
                        <li>
                            <strong>Sondages :</strong> Nous pouvons vous contacter pour vous inviter à participer à des sondages. Si vous choisissez de participer au sondage, nous pouvons collecter des informations personnelles de votre part.
                        </li>
                        <li>
                            <strong>Fonctionnalités interactives :</strong> Les informations personnelles que vous soumettez ou rendez disponibles via nos fonctionnalités interactives (par exemple, messagerie et fonctionnalités de chat, commentaires, forums, blogs et pages de médias sociaux) peuvent être collectées par nous et d'autres utilisateurs de nos Services. Toute information que vous fournissez en utilisant les fonctionnalités de partage public des Services sera considérée comme "publique", sauf si la loi applicable exige le contraire, et n'est pas soumise aux garanties de confidentialité mentionnées ici.
                        </li>
                        <li>
                            <strong>Jeux-concours ou Concours :</strong> Nous pouvons collecter des informations personnelles de votre part si vous participez à l'un de nos jeux-concours ou concours. Dans certaines juridictions, nous sommes tenus de partager publiquement des informations sur les gagnants de jeux-concours et concours.
                        </li>
                        <li>
                            <strong>Conférences, Salons et Autres Événements :</strong> Lorsque nous assistons ou organisons des conférences et autres événements, nous pouvons collecter des informations personnelles auprès des individus.
                        </li>
                        <li>
                            <strong>Développement commercial et Partenariats stratégiques :</strong> Pour évaluer et poursuivre des opportunités commerciales potentielles, nous pouvons collecter des informations personnelles auprès d'individus et de tiers.
                        </li>
                        <li>
                            <strong>Candidatures :</strong> Nous nous réservons le droit de publier des offres d'emploi et des opportunités. Nous pouvons collecter vos informations personnelles si vous répondez à l'une de ces annonces, telles que votre candidature, CV, lettre de motivation, et/ou toute autre information que vous nous fournissez.
                        </li>
                    </ul>

                    <h3>B. Informations Personnelles Collectées Automatiquement</h3>
                    <p>Lorsque vous utilisez nos Services, nous pouvons collecter automatiquement des informations personnelles.</p>

                    <ul>
                        <li>
                            <strong>Collecte automatique d'informations personnelles :</strong> Lorsque vous utilisez nos Services, nous pouvons automatiquement collecter des informations telles que votre adresse IP, vos paramètres utilisateur, l'adresse MAC, les identifiants de cookies, l'opérateur mobile, la publicité mobile et d'autres identifiants uniques, les informations sur le navigateur ou l'appareil, les informations de localisation (y compris la localisation approximative dérivée de l'adresse IP) et le fournisseur d'accès à Internet. Nous pouvons également collecter automatiquement des informations sur votre utilisation de nos Services, telles que les pages que vous visitez avant, pendant et après l'utilisation de nos Services, les informations sur les liens sur lesquels vous cliquez, les types de contenu avec lesquels vous interagissez, la fréquence et la durée de vos activités, et d'autres informations sur la manière dont vous utilisez nos Services.
                        </li>
                        <li>
                            <strong>Politique de cookies (et autres technologies) :</strong> Nous, ainsi que des fournisseurs tiers de contenu, de publicité ou d'autres fonctionnalités sur nos Services, pouvons utiliser des cookies, des balises pixels et d'autres technologies ("Technologies") pour collecter automatiquement des informations lorsque vous utilisez nos Services.
                        </li>
                        <li>
                            <strong>Cookies :</strong> Les cookies sont de petits fichiers texte stockés dans les navigateurs des appareils pour faciliter et améliorer votre expérience.
                        </li>
                        <li>
                            <strong>Balises Pixel/Balises Web :</strong> Une balise pixel (également connue sous le nom de balise web) est un morceau de code intégré dans nos Services qui collecte des informations sur la manière dont nos Services sont utilisés. Nous pouvons utiliser des balises pixel pour enregistrer, par exemple, qu'un utilisateur a visité une page web spécifique ou cliqué sur une publicité spécifique. Les balises web peuvent également être incluses dans les e-mails pour déterminer si les messages ont été ouverts, agis ou transférés.
                        </li>
                        <li>
                            <strong>Opérationnellement nécessaire :</strong> Cela inclut les technologies qui vous donnent accès à nos Services, applications et outils nécessaires pour détecter un comportement inhabituel du site web, prévenir les activités frauduleuses, améliorer la sécurité ou vous permettre d'utiliser nos fonctionnalités.
                        </li>
                        <li>
                            <strong>Lié aux performances :</strong> Nous pouvons utiliser des Technologies pour évaluer la performance de nos Services, y compris des analyses pour mieux comprendre comment les gens utilisent nos Services (voir Analytique ci-dessous).
                        </li>
                        <li>
                            <strong>Lié aux fonctionnalités :</strong> Nous pouvons utiliser des Technologies qui nous permettent de vous fournir une fonctionnalité améliorée lors de l'accès ou de l'utilisation de nos Services. Cela peut inclure de vous reconnaître lorsque vous vous connectez à nos Services ou de mémoriser vos préférences spécifiées, vos intérêts ou les articles précédemment consultés.
                        </li>
                        <li>
                            <strong>Lié à la publicité ou au ciblage :</strong> Nous pouvons utiliser des Technologies de première ou de tierce partie pour diffuser du contenu, y compris des publicités pertinentes à vos intérêts, sur nos Services ou sur des propriétés numériques tierces. Pour en savoir plus sur vos options et droits en matière de confidentialité en relation avec ces technologies, voir "Vos choix et droits en matière de confidentialité."
                        </li>
                        <li>
                            <strong>Analyses :</strong> Pour traiter les données analytiques sur nos Services, nous pouvons utiliser des Technologies et d'autres outils tiers. Ces technologies nous permettent d'avoir une meilleure compréhension de la manière dont nos Services numériques sont utilisés et de les améliorer et les personnaliser en continu. Google Analytics est l'un de nos partenaires d'analyse. Veuillez consulter <a href="https://policies.google.com/technologies/partner-sites">la Politique de confidentialité de Google Analytics</a> pour plus d'informations sur la manière dont Google utilise vos informations personnelles (y compris à ses propres fins, comme le profilage ou la liaison à d'autres données). Cliquez <a href="https://tools.google.com/dlpage/gaoptout">ici</a> pour en savoir plus sur la manière de vous désinscrire de l'utilisation de vos informations par Google Analytics.
                        </li>
                    </ul>

                    <h3>C. Informations Personnelles Collectées Auprès d'Autres Sources</h3>
                    <p><strong>Services et Sources Tiers.</strong> Des informations personnelles vous concernant peuvent être obtenues à partir d'autres sources, notamment des services et organisations tiers. Par exemple, si vous accédez à nos Services via une application tierce, comme une boutique d'applications, un service de connexion tiers ou un site de réseautage social, nous pouvons collecter des informations personnelles vous concernant que vous avez rendues disponibles via vos paramètres de confidentialité de cette application tierce.</p>

                    <h2>3. COMMENT NOUS UTILISONS VOS INFORMATIONS PERSONNELLES</h2>
                    <p>Comme décrit ci-dessous, nous utilisons vos informations personnelles à diverses fins commerciales, notamment pour fournir nos Services, à des fins administratives et pour promouvoir nos produits et Services.</p>

                    <h3>A. Fournir Nos Services</h3>
                    <p>Nous utilisons vos informations pour exécuter notre contrat avec vous et vous fournir nos Services, ce qui comprend : Gérer vos informations et comptes ; Vous donner accès à des zones, fonctionnalités et caractéristiques spécifiques de nos Services ; Répondre aux demandes de support client ou technique ; Nous communiquerons avec vous concernant votre compte, nos Services et les modifications de politique. Traiter vos informations financières et d'autres méthodes de paiement pour les produits ou services achetés ; Si vous postulez à une offre d'emploi que nous publions sur nos Services, nous traiterons votre candidature ; et nous vous permettrons de vous inscrire à des programmes éducatifs ou à d'autres événements, offres, programmes ou communautés.</p>

                    <h3>B. À des Fins Administratives</h3>
                    <p>Nous utilisons vos informations à diverses fins administratives, notamment : Poursuivre nos intérêts légitimes, qui comprennent le marketing direct, la recherche et le développement (y compris la recherche marketing), la sécurité réseau et l'information, et la prévention de la fraude. Détecter des incidents de sécurité, se protéger contre la tromperie malveillante, la fraude ou l'activité illégale, et poursuivre les responsables ; Mesurer l'intérêt et la participation à nos Services ; améliorer, mettre à niveau ou perfectionner nos services ; Créer de nouveaux produits et services ; Contrôle de qualité interne et assurance de la sécurité ; Authentification et vérification de l'identité individuelle, y compris les demandes d'exercice de vos droits en vertu de la présente Politique de Confidentialité ; Auditer les interactions, les transactions et d'autres activités de conformité ; Déboguer pour identifier et réparer les erreurs de nos Services ; Partager des informations personnelles avec des tiers si nécessaire pour la fourniture des Services ; Faire respecter nos accords et politiques ; et Mener des activités nécessaires pour répondre à nos obligations légales.</p>

                    <h3>C. Marketing et Publicité pour nos Produits et Services</h3>
                    <p>Nous pouvons utiliser des informations personnelles pour adapter et vous présenter des contenus et des publicités. Nous pouvons vous fournir ces matériaux conformément à la loi applicable. Les campagnes par e-mail, les messages texte, la publicité pour des audiences personnalisées et la publicité "basée sur les intérêts" ou "publicité personnalisée", y compris le suivi multi-appareils, sont certaines des méthodes que nous pourrions utiliser pour vous faire du marketing. Si vous avez des questions concernant nos pratiques marketing, veuillez nous contacter à tout moment en utilisant les informations fournies dans la section "Contactez-nous" ci-dessous.</p>

                    <h3>D. Avec Votre Consentement</h3>
                    <p>Nous pouvons utiliser des informations personnelles à d'autres fins qui vous sont clairement indiquées lorsque vous fournissez des informations personnelles ou avec votre consentement.</p>

                    <h3>E. Autres Fins</h3>
                    <p>Nous utilisons également vos informations personnelles à d'autres fins que vous avez demandées ou qui sont autorisées par la loi applicable. Des données désidentifiées et agrégées. Nous pouvons utiliser des informations personnellement identifiables pour créer des données désidentifiées et/ou agrégées, comme des informations sur l'appareil à partir duquel vous accédez à nos Services ou d'autres analyses que nous créons.</p>

                    <h2>4. COMMENT NOUS DIVULGUONS VOS INFORMATIONS PERSONNELLES</h2>
                    <p>Afin de fournir nos Services, de nous protéger et de protéger les autres, ou dans le cas d'une transaction commerciale importante comme une fusion, une vente ou un transfert d'actifs, comme expliqué ci-dessous, nous pouvons divulguer vos informations personnelles à des tiers à ces fins et à d'autres fins commerciales.</p>

                    <h3>A. Divulgations pour Fournir nos Services</h3>
                    <p>Voici une liste des différents types de tiers avec lesquels nous pourrions partager vos informations personnelles.</p>

                    <ul>
                        <li>
                            <strong>Fournisseurs de services :</strong> Vos informations personnelles pourraient être divulguées à nos fournisseurs et prestataires de services tiers qui nous aident à fournir nos services. Ces entreprises et fournisseurs nous apportent un soutien informatique, authentification, sécurité, hébergement, traitement des paiements, analyses, alertes, service client et autres services connexes.
                        </li>
                        <li>
                            <strong>Services tiers avec lesquels vous partagez ou interagissez :</strong> En tant que groupe, ces sites Web, services, produits et technologies tiers (collectivement "Services tiers") peuvent être accessibles et/ou utilisés via certaines fonctionnalités et fonctionnalités des Services. La politique de confidentialité d'un Service tiers peut s'appliquer à toute information partagée avec lui ou autrement collectée par lui. Nous n'avons aucun contrôle sur la manière dont les Services tiers utilisent vos informations personnelles.
                        </li>
                        <li>
                            <strong>Partenaires commerciaux :</strong> Afin de vous fournir le produit ou le service que vous avez demandé, nous pourrions partager vos informations personnelles avec nos partenaires commerciaux. Vos informations personnelles pourraient également être divulguées à des partenaires commerciaux avec lesquels nous co-marquons des biens et services.
                        </li>
                        <li>
                            <strong>Filiales :</strong> Nous pourrions partager vos informations personnelles avec nos filiales de l'entreprise, s'il y en a.
                        </li>
                        <li>
                            <strong>Partenaires publicitaires :</strong> Nous pourrions fournir à des partenaires publicitaires tiers un accès à vos informations personnelles. Ces partenaires publicitaires tiers peuvent installer des technologies et d'autres outils de suivi sur nos services pour collecter des données sur vos activités et votre appareil (tels que votre adresse IP, les identifiants de cookies, les pages visitées, la localisation et l'heure de la journée). Lorsque vous visitez des propriétés numériques au sein de leurs réseaux, ces partenaires publicitaires peuvent utiliser ces informations (ainsi que des données similaires collectées à partir d'autres services) pour vous livrer des publicités personnalisées. Cette méthode est également connue sous le nom de "publicité personnalisée" ou "publicité basée sur les centres d'intérêt."
                        </li>
                        <li>
                            <strong>API/SDK :</strong> Des interfaces de programmation d'applications ("API") et des kits de développement logiciel ("SDK") de tiers peuvent être utilisés ou fournis par nous comme composant des Services.
                        </li>
                    </ul>

                    <h3>B. Divulgations pour nous protéger ou protéger les autres</h3>
                    <p>Nous pouvons accéder, préserver et divulguer toute information associée à vous à des tiers si nous pensons que cela est nécessaire ou approprié pour : se conformer aux demandes des forces de l'ordre ou à la sécurité nationale et aux procédures légales, comme une ordonnance judiciaire ; protéger vos droits, les nôtres ou ceux d'autres personnes, la propriété ou la sécurité ; appliquer nos politiques ou contrats ; recouvrer les sommes qui nous sont dues ; ou aider à une enquête ou à des poursuites.</p>

                    <h3>C. Divulgation lors de fusion, vente ou autre transfert d'actifs</h3>
                    <p>Vos informations peuvent être vendues ou transférées dans le cadre d'une telle transaction, conformément à la loi et/ou au contrat, si nous sommes impliqués dans une fusion, une acquisition, une due diligence financière, une réorganisation, une faillite, une mise sous séquestre, un achat ou une vente d'actifs, ou une transition de service vers un autre fournisseur.</p>

                    <h2>5. VOS CHOIX ET DROITS EN MATIÈRE DE CONFIDENTIALITÉ</h2>
                    <p><strong>Vos options en matière de confidentialité.</strong> Les choix de confidentialité que vous pouvez avoir concernant vos informations personnelles sont décrits ci-dessous et sont déterminés par la législation applicable.</p>

                    <ul>
                        <li>
                            <strong>Correspondance par email :</strong> Si vous recevez un email de notre part que vous ne souhaitez pas recevoir, vous pouvez vous désabonner en utilisant le lien situé en bas de l'email ou en nous contactant comme décrit dans "Contactez-nous" ci-dessous. Gardez à l'esprit que vous recevrez toujours des emails liés aux transactions concernant les biens ou services que vous avez commandés. De plus, nous pourrions vous envoyer des communications non promotionnelles sur nous et nos services ; cependant, vous ne pourrez pas choisir de ne pas les recevoir.
                        </li>
                        <li>
                            <strong>Textos SMS :</strong> Si vous recevez un texto indésirable de notre part, vous pouvez choisir de ne plus recevoir de textos de notre part en suivant les instructions contenues dans ce texto ou en nous contactant comme décrit ci-dessous dans "Contactez-nous".
                        </li>
                        <li>
                            <strong>Appels téléphoniques :</strong> Vous pouvez choisir de ne plus recevoir d'appels de notre part à l'avenir si vous recevez un appel marketing indésirable de notre part en suivant les instructions qui peuvent être fournies lors de l'appel ou en nous contactant comme décrit dans "Contactez-nous" ci-dessous.
                        </li>
                        <li>
                            <strong>“Ne pas suivre” :</strong> Ne Pas Suivre (“DNT” pour "Do Not Track") est une préférence de confidentialité que les utilisateurs peuvent définir dans certains navigateurs web. Veuillez noter que nous ne répondons pas et ne respectons pas les signaux DNT ou des mécanismes similaires transmis par les navigateurs web.
                        </li>
                        <li>
                            <strong>Publicité personnalisée et cookies :</strong> En modifiant vos préférences comme le permettent votre navigateur ou votre appareil, vous pouvez empêcher ou restreindre le placement de Technologies sur votre appareil ou les supprimer. Mais si vous modifiez vos préférences, nos Services pourraient ne pas fonctionner comme prévu. Veuillez noter que les applications mobiles ne peuvent pas être bloquées à l'aide d'options de désactivation basées sur des cookies. Sur certaines applications mobiles, cependant, vous pouvez choisir de ne pas voir de publicités personnalisées en le faisant en adhérant aux directives pour <a href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=fr">Android</a>, <a href="https://support.apple.com/fr-fr/HT202074">iOS</a>, et <a href="https://thenai.org/opt-out/mobile-opt-out/">autres plateformes</a>. De plus, le secteur de la publicité en ligne offre des sites web où vous pouvez choisir de ne pas recevoir de publicités ciblées de partenaires de données et d'autres partenaires publicitaires qui participent à des initiatives d'autorégulation. Visitez l'<a href="https://thenai.org/opt-out/">Initiative de Publicité en Réseau</a>, l'<a href="https://optout.aboutads.info/?c=2&lang=FR">Alliance pour la Publicité Numérique</a>, l'<a href="https://youronlinechoices.eu">Alliance Européenne pour la Publicité Numérique</a>, et l'<a href="https://youradchoices.ca/fr/tools">Alliance pour la Publicité Numérique du Canada</a> pour accéder à ces ressources et en savoir plus sur la publicité ciblée, le choix du consommateur, et la vie privée. Veuillez noter que vous devez vous désinscrire individuellement de chaque navigateur et appareil.
                        </li>
                    </ul>

                    <p><strong>Vos droits en matière de confidentialité.</strong> Conformément à la législation applicable, vous pourriez avoir le droit de :</p>

                    <ul>
                        <li>
                            <strong>Accès et portabilité de vos informations personnelles :</strong> Cela inclut :
                            <ul>
                                <li>(i) déterminer si nous traitons vos données personnelles ;</li>
                                <li>(ii) demander l'accès à vos données personnelles ou en obtenir une copie ;</li>
                                <li>(iii) recevoir une copie électronique des données personnelles que vous nous avez fournies ou demander que nous envoyions ces données à une autre entreprise dans un format structuré, couramment utilisé et lisible par machine (également appelé le "droit à la portabilité des données") ;</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Correction :</strong> Si vos informations personnelles sont incorrectes ou incomplètes, demander une correction. Dans certains cas, nous pourrions proposer des outils en libre-service pour que vous puissiez mettre à jour vos données personnelles ;
                        </li>
                        <li>
                            <strong>Suppression :</strong> Demander la suppression de vos données personnelles ;
                        </li>
                        <li>
                            <strong>Restriction ou objection :</strong> Demander une restriction sur notre utilisation de vos informations personnelles ou y objecter ;
                        </li>
                        <li>
                            <strong>Refus :</strong> Refuser de nous donner la permission de traiter vos informations personnelles. Veuillez noter que votre retrait ne s'appliquera qu'au traitement qui sera effectué à l'avenir ; il n'aura pas d'impact sur le traitement qui a été effectué légalement avant votre retrait.
                        </li>
                    </ul>

                    <p>Veuillez nous contacter en utilisant les informations sous la rubrique "Nous Contacter" si vous souhaitez exercer l'un de ces droits. Ces demandes seront traitées conformément aux exigences légales pertinentes.</p>

                    <h2>6. SÉCURITÉ DE VOS INFORMATIONS</h2>
                    <p>Nous prenons des précautions pour garantir la sécurité et le respect de cet Avis de Confidentialité dans le traitement de vos informations. Cependant, la sécurité des informations que vous nous fournissez n'est pas garantie ou assurée par nous. Nous déclinons toute responsabilité pour l'accès, l'utilisation, la divulgation ou la perte non autorisés d'informations personnelles dans toute la mesure permise par la loi applicable. Vous acceptez que nous puissions communiquer avec vous électroniquement sur des questions de sécurité, de confidentialité et opérationnelles relatives à votre utilisation de nos Services en utilisant nos Services ou en nous fournissant des informations personnelles. Si nous prenons connaissance d'une faille de sécurité du système, nous pourrions essayer de vous alerter électroniquement en vous envoyant un courrier électronique, en publiant un avis sur nos Services ou en vous envoyant une lettre.</p>

                    <h2>7. TRANSFERTS INTERNATIONAUX DE DONNÉES</h2>
                    <p>Toute information que nous traitons peut être transférée, traitée et stockée n'importe où dans le monde, y compris, mais sans s'y limiter, aux États-Unis ou dans d'autres nations ayant des réglementations sur la protection des données qui peuvent différer de celles dans votre pays de résidence. Nous nous efforçons de protéger vos informations conformément aux exigences de toute loi pertinente.</p>

                    <p>Les <a href="https://commission.europa.eu/publications/standard-contractual-clauses-international-transfers_en">Clauses Contractuelles Types de l'UE</a> sont l'une des garanties que nous pourrions utiliser pour soutenir un transfert de données personnelles provenant de l'Espace Économique Européen, de la Suisse et/ou du Royaume-Uni vers un pays qui n'a pas été jugé offrir un niveau de protection adéquat en vertu des lois applicables sur la protection des données.</p>

                    <h2>8. CONSERVATION DES INFORMATIONS PERSONNELLES</h2>
                    <p>Tant que vous utilisez nos services, ou aussi longtemps que nécessaire pour atteindre le(s) objectif(s) pour lequel elles ont été collectées, fournir nos services, résoudre les litiges, établir des défenses juridiques, réaliser des audits, poursuivre des objectifs commerciaux légaux, faire respecter nos accords et se conformer aux lois applicables, nous conservons les informations personnelles que nous collectons comme décrit dans cet Avis de Confidentialité.</p>

                    <h2>9. AVIS COMPLÉMENTAIRE POUR LES RÉSIDENTS DE CALIFORNIE Droits supplémentaires en matière de confidentialité pour les résidents de Californie</h2>
                    <p><strong>Vente d'informations personnelles</strong> tel que défini par le CCPA OPUS France ne "vend" pas d'informations personnelles aux fins du CCPA, et nous n'avons pas connaissance de toute "vente" d'informations personnelles de mineurs de moins de 16 ans.</p>

                    <p><strong>Non-discrimination.</strong> Les résidents de Californie ont le droit de ne pas subir de discrimination de notre part lorsqu'ils exercent leurs droits accordés par le CCPA.</p>

                    <p><strong>Agent désigné.</strong> Seul vous, ou un représentant légalement habilité à agir en votre nom, pouvez soumettre une demande de consommateur vérifiée concernant vos données personnelles. Une demande de consommateur vérifiable peut également être faite au nom de votre enfant mineur. Si vous souhaitez désigner un agent, vous devez le faire par écrit et le faire signer à la fois par vous et par la personne que vous avez choisie. Pour plus d'informations, contactez-nous en utilisant les informations sous "Contactez-nous" ci-dessous.</p>

                    <p><strong>Vérification.</strong> Avant de compléter votre demande, nous prendrons des mesures pour vérifier raisonnablement votre identité afin de protéger votre vie privée. Ces actions pourraient impliquer de vous demander des informations qui nous permettent de confirmer raisonnablement que vous êtes la personne sur laquelle nous avons collecté des informations personnelles ou un représentant légitime, ou elles pourraient impliquer de vous poser des questions sur votre compte et l'utilisation de nos services. Si vous vivez en Californie et souhaitez exercer l'un de vos droits CCPA, veuillez nous contacter en utilisant les informations fournies sous "Contactez-nous" ci-dessous. Ces demandes seront traitées conformément aux exigences légales pertinentes.</p>

                    <p><strong>Données qui ont été désidentifiées.</strong> Sauf pour se conformer à la loi applicable, nous ne tenterons pas de réidentifier les informations désidentifiées que nous créons ou recevons.</p>

                    <h2>10. AVIS COMPLÉMENTAIRE POUR LES RÉSIDENTS DU NÉVADA</h2>
                    <p>Si vous résidez au Nevada, vous avez le choix de ne pas permettre que certaines informations personnelles soient vendues à des tiers qui prévoient de les licencier ou de les vendre. Si applicable, vous pouvez exercer ce droit en nous envoyant un e-mail à l'adresse indiquée ci-dessous dans la section "Nous Contacter", avec la ligne d'objet "Nevada Do Not Sell Request" et en vous identifiant ainsi que l'adresse e-mail associée à votre compte. Veuillez noter qu'en vertu du chapitre 603A des Statuts révisés du Nevada, nous ne vendons actuellement pas vos informations personnelles. Les informations de contact sont fournies ci-dessous dans la section Contactez-nous si vous avez des questions.</p>

                    <h2>11. INFORMATIONS SUR LES ENFANTS</h2>
                    <p>Les enfants de moins de 16 ans ne sont pas destinés à utiliser les Services, et nous ne collectons pas sciemment d'informations personnelles les concernant. Vous pouvez nous contacter comme indiqué dans "Nous Contacter" ci-dessous si vous êtes un parent ou un tuteur légal et pensez que votre enfant a téléchargé des informations personnelles sur notre site sans votre autorisation. Si nous apprenons qu'un enfant nous a fourni des informations personnelles en violation de la loi applicable, nous supprimerons ces informations, à moins que la loi ne nous oblige à les conserver, et, si nécessaire, nous mettrons également fin au compte de l'enfant.</p>

                    <h2>12. AUTRES DISPOSITIONS</h2>
                    <p><strong>Sites Web et applications de tiers.</strong> Nos Services et d'autres sites Web et applications peuvent être référencés ou liés sur d'autres sites Web et applications. Nous n'avons aucun contrôle sur ces services fournis par des tiers. Nous conseillons à tous nos utilisateurs de lire les déclarations de confidentialité sur chaque site Web et application qu'ils utilisent. Les politiques de confidentialité ou le contenu de ces autres sites Web ou applications ne sont pas pris en charge, examinés ou approuvés par nous, et nous ne sommes pas responsables pour eux. Vous le faites à vos propres risques lorsque vous fournissez des informations personnelles à des sites Web ou des applications tiers.</p>

                    <p><strong>Autorité de surveillance.</strong> Si vous pensez que notre traitement de vos informations personnelles enfreint les lois applicables dans l'Espace Économique Européen, la Suisse ou le Royaume-Uni, où vos informations personnelles sont soumises à ces lois, vous avez le droit de déposer une plainte auprès de l'autorité de surveillance compétente.</p>

                    <ul>
                        <li><a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">Autorités de protection des données de l'EEE (DPAs)</a></li>
                        <li><a href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html">Commissaire fédéral suisse à la protection des données et à la transparence (FDPIC)</a></li>
                        <li><a href="https://ico.org.uk/global/contact-us/">Bureau du Commissaire à l'information du Royaume-Uni (ICO)</a></li>
                    </ul>

                    <h2>13. CONTACTEZ-NOUS</h2>
                    <p>OPUS France est responsable du contrôle des données personnelles que nous traitons conformément à cet Avis de confidentialité. Contactez-nous à <a href="mailto:contact@72h-agency.com">contact@72h-agency.com</a> si vous avez des questions concernant nos pratiques de confidentialité, cet avis de confidentialité, ou pour exercer les droits décrits dans cet avis de confidentialité.</p>

                </div>

            </div>
            <Footer/>
        </div>

    )
}
