import React from "react";
import logo from "../../assets/72h_logo.svg"
import whiteArrow from "../../assets/whiteArrow.svg"
import {useTranslation} from "react-i18next";
export default function MainMenu({width}) {

    const { t } = useTranslation();

    const scrollToElement = (selector) => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return(
        <div className={'mainMenu'}>

            <div style={{display: 'flex', alignItems: 'center', gap: 50}}>
                <img src={logo} alt="" className={'logo'}/>
                {width > 786 && <p className={'link hover'} onClick={() => scrollToElement("#lastWork")}>{t('header.realisations')}</p>}
                {width > 659 && <p className={'link hover'} onClick={() => scrollToElement("#pricing")}>{t('header.tarifs')}</p>}
                {width > 659 && <p className={'link hover'} onClick={() => scrollToElement("#expertises")}>{t('header.expertises')}</p>}
            </div>

            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                <div className={'callToAction bounceHover'} style={{marginRight: width < 550 ? 15 : null}}>
                    {width > 525 ? t('header.requestMockup') : t('header.bookAppointment')}
                    <img src={whiteArrow} alt=""/>
                </div>
            </a>

        </div>
    )
}
