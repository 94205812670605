import React from "react";
import timeline from '../../assets/timeline.svg'
import timelineEn from '../../assets/timelineEn.svg'
import whiteArrow from '../../assets/whiteArrow.svg'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export default function Timeline({width}) {

    const currentLanguage = i18next.language;

    const { t} = useTranslation();

    return(
        <div className={'timeline'}>
            <p className={'classicTitle'} style={{width: 470}} dangerouslySetInnerHTML={{ __html: t('timeline.classicTitle') }}></p>
            <p className={'classicSubtitle'}>{t('timeline.classicSubtitle')}</p>
            <div className={'containerTimeline'}>
                <div className={'comble'}>72h</div>
                <img src={currentLanguage.includes('fr') ? timeline : timelineEn} alt="" className={'timelineImg'}/>
                <div className={'comble'}>72h</div>
            </div>

            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                <div className={'callToAction bounceHover'}>
                    {width > 550 ? t('timeline.startRightAwayWithFirstStep') : t('timeline.startRightNow')}
                    <img src={whiteArrow} alt=""/>
                </div>
            </a>

            <p className={'info'}>{t('timeline.info')}</p>
        </div>
    )
}
