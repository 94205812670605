import React from "react";
import checkTheme from "../../assets/checkTheme.svg"
import choice from "../../assets/choice.svg"
import choiceEn from "../../assets/choiceEn.svg"
import geometric from "../../assets/geometric.svg"
import SvgToolIcon from "../../assets/SvgToolIcon";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
export default function Pricing({width}) {

    const { t } = useTranslation();
    const currentLanguage = i18next.language;

    const options = [
        {name: 'programmedIn72h', freeTrial: true},
        {name: 'programmedInReact', freeTrial: true},
        {name: 'multilingualWebsite', freeTrial: false},
        {name: 'correctionsForAMonth', freeTrial: true},
        {name: 'associatedDomainName', freeTrial: true},
        {name: '30DaysMoneyBack', freeTrial: true},
    ]

    return(
        <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <div className={'headerPricing'} style={{zIndex: 1}}>

                <img src={geometric} alt="" className={'geometric'} style={{zIndex: 0}}/>

                <div style={{display: 'flex', position: 'absolute', marginTop: 0}} id={'pricing'}/>

                <h2 className={'classicTitle title'} style={{width: 500, position: 'absolute', color: '#FFF'}} dangerouslySetInnerHTML={{ __html: t('pricing.classicTitle') }} />

                <p className={'classicSubtitle subtitle'} style={{zIndex: 3, color: 'rgba(255,255,255,0.72)'}}>{t('pricing.classicSubtitle')}</p>
            </div>

            <div className={'planChoice'} style={{zIndex: 2}} >

                <div className={'containerChoices'} style={{flexDirection: width < 1095 ? 'column' : 'row', paddingBottom: 150}}>
                    <div>
                        <PlanToChoice popular={true} freeTrial={true} planName={'Maquette'} free={true} options={options} />
                        {width > 1094 &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={currentLanguage.includes('fr') ? choice : choiceEn} alt="" style={{width: 180, marginTop: 100}}/>
                            </div>
                        }

                    </div>
                    <PlanToChoice freeTrial={true} planName={'Professional'} price={'1980'} options={options} oldPrice={'3980'}/>
                    <PlanToChoice planName={'Business'} price={'2980'} options={options} oldPrice={'5980'}/>
                </div>

            </div>
        </div>

    )
}

function PlanToChoice({freeTrial, planName, price, options, free, popular, oldPrice}) {

    const { t } = useTranslation();

    return(
        <div className={'planToChoice'} style={{height: free ? null : 720, paddingBottom: free ? 20 : null, marginTop: free && 62}}>
            <p className={'planName'}>{t(`pricing.planName.${planName.toLowerCase()}`)}</p>
            {!free && <p className={'oldPrice'}>{oldPrice}<span style={{fontSize: 20}}> €</span></p>}
            {free ?
                null
                :
                <p className={'planPrice'}>{price}<span style={{fontSize: 20}}>{t('pricing.planPriceSuffix')}</span></p>
            }
            <p className={'infoPlan'}>{free ? t('pricing.letUsWork') : t('pricing.billedOnAcceptance')}</p>
            <div className={'credits'}>
                <SvgToolIcon color={'#5973F4'} width={30} borderWidth={4} height={30}/>
                <p className={'aboutCredits'}>{t(`pricing.infoPrice.${planName.toLowerCase()}`)}</p>
            </div>

            <div className={'bar'}/>

            <a href={'https://calendly.com/72h-agency/30min?back=1&month=2024-03'} target="_blank">
                <div style={{display: 'flex', width: 400, justifyContent: 'center'}}>
                    <div className={popular ? 'buttonStartFreeTrial' : 'buttonChoosePlan'}>
                        {free ? t('pricing.requestMockupProposal') : t('pricing.requestWebsite') }
                    </div>

                </div>

            </a>


            {!free &&
                <div className={'listOptions'}>
                    {freeTrial ?

                        options.map((option) => (
                            <div className={'itemOption'} style={{opacity: option.freeTrial ? 1 : 0.4}}>
                                <img src={checkTheme} alt=""/>
                                {t(`pricing.options.${option.name.replace(/ /g, "")}`)}
                            </div>
                        ))

                        :

                        options.map((option) => (
                            <div className={'itemOption'}>
                                <img src={checkTheme} alt=""/>
                                {t(`pricing.options.${option.name.replace(/ /g, "")}`)}
                            </div>
                        ))
                    }
                </div>
            }


        </div>
    )
}
